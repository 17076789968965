import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "./assets/fonts/stylesheet.css";

Sentry.init({
	dsn:
		"https://e094946d46ea4bc9a724658fcbda3ce7@o128210.ingest.sentry.io/5236059"
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
