import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Page, Container } from "../../utils/sharedStyles";

import bannerImage from "../../assets/alt/check-in.png";

const AuthorizePageContainer = styled(Page)`
  display: grid;
  place-items: center;

  .card {
    text-align: center;
  }
  .banner-image {
    max-width: 400px;
    margin-bottom: 2rem;

    @media (max-width: 550px) {
      max-width: 250px;
    }
  }

  .caption {
    line-height: 2;
  }

  .rs-loader-spin {
    &::after {
      border-color: #f44336 transparent transparent;
    }
    &::before {
      border-color: #d9d9d9;
    }
  }
`;

interface AuthorizePageProps {}
const AuthorizePage: React.FC<AuthorizePageProps> = () => {
  return (
    <AuthorizePageContainer>
      <Container>
        <div className="card">
          <img className="banner-image " src={bannerImage} alt="" />
          <br />
          <p className="caption">
            Hey there, We are checking you in. <br /> Thanks for your patience
            😊{" "}
          </p>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      </Container>
    </AuthorizePageContainer>
  );
};

export default AuthorizePage;
