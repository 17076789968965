import React, { useState } from "react";
import styled from "styled-components";
import { Avatar, Dropdown, Menu, Skeleton } from "antd";

import { Container } from "../../utils/sharedStyles";
import { useUser } from "../../contexts/UserContext";
import Logo from "../Logo";
import { Link } from "react-router-dom";

const NavbarContainer = styled.div`
  padding: 0.5rem 0;
  position: sticky;
  z-index: 3;

  background-color: white;
  margin: 0 auto;
  border-radius: 1rem;
  box-shadow: 0 19px 17px -22px #809ab996;

  @media (min-width: 550px) {
    ${Container} {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    top: 0;
    width: 100%;
    border-radius: 0;
  }
`;

const NavbarGrid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 7rem min-content;
  align-items: center;
  padding: 0.5rem 0;

  .logo-container {
    height: min-content;
  }

  .logout-btn {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transform: scale(1);

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-color);
      transform: scale(1.05);
    }
  }

  h3 {
    margin: 0;
  }
`;

const NavbarMenu = styled(Menu)`
  min-width: 8rem;
`;

const LogoutButton = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const handleClick = async () => {
    try {
      setLoading(true);
    } catch (error) {
      console.error(error);
    }
  };
  return loading ? (
    <Skeleton.Avatar />
  ) : (
    <Dropdown
      placement="bottomRight"
      overlay={
        <NavbarMenu>
          <Menu.Item onClick={handleClick}>Logout</Menu.Item>
        </NavbarMenu>
      }
    >
      <Avatar src={user?.picture} />
    </Dropdown>
  );
};

/* <div className="logout-btn" role="button" onClick={handleClick}>
      <LogoutIcon />
    </div> */

const Navbar = () => {
  const { user } = useUser();
  return (
    (user?.id && (
      <NavbarContainer>
        <Container>
          <NavbarGrid>
            <Logo />
            <LogoutButton />
          </NavbarGrid>
        </Container>
      </NavbarContainer>
    )) ||
    null
  );
};

export default Navbar;

export const NavbarStyled = styled.div`
  padding: 0.8rem 0;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 3;

  ${Container} {
    display: grid;
    grid-template-columns: 10rem 1fr min-content;
    grid-template-areas: "logo gap cta";
    grid-gap: 1rem;
  }

  .logo-container {
    grid-area: logo;
  }
`;
export const PublicNavbar = () => {
  return (
    <NavbarStyled>
      <Container>
        <Link to="/">
          <Logo />
        </Link>
      </Container>
    </NavbarStyled>
  );
};
