import React from "react";
import styled from "styled-components";
import { Spin, Button as AntDButton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Container = styled.div`
  position: relative;
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 400px) {
    width: 95%;
    padding: 0;
  }

  /* For devices larger than 550px */
  @media (max-width: 550px) {
    width: 90%;
    padding: 0;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const Page = styled.div`
  min-height: 100vh;
`;

export const Card = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 12px 20px,
    rgba(0, 0, 0, 0.06) 0px 2px 12px;
`;

export const Loader: React.FC<{
  tip?: string;
  spinning?: boolean;
}> = props => (
  <Spin
    tip={props.tip}
    spinning={props.spinning}
    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
  >
    {props.children}
  </Spin>
);

export const Button = styled(AntDButton)`
  height: 2.4rem;
  border-radius: 0.5rem;
  padding: 0 2rem;
`;
