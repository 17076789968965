import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "antd/dist/antd.css";
// pages
import LoginPage from "./pages/LoginPage";

// components
import { GlobalStyle } from "./utils/globalStyles";
import PrivateRoute from "./components/PrivateRoute";
import LoginRoute from "./components/LoginRoute";

import { ContextProvider } from "./contexts";

import AuthorizePage from "./pages/AuthorizePage";
import usePageViews from "./components/AnalyticsListener";
import env from "./utils/env";

const FullStorySetup = lazy(() => import("./components/FullStorySetup"));
const DashboardApp = lazy(() => import("./components/DashboardApp"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const UnsubscribePage = lazy(() => import("./components/UnsubscribePage"));

declare var window: any;

const Routes = () => {
	usePageViews();

	useEffect(() => {
		if (env.REACT_APP_CRISP) {
			// Include the Crisp code here, without the <script></script> tags
			window.$crisp = [];
			window.CRISP_WEBSITE_ID = env.REACT_APP_CRISP;

			const d = document;
			const s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			s.async = true;
			d.getElementsByTagName("head")[0].appendChild(s);
		}
	}, []);

	return (
		<Switch>
			<LoginRoute path='/login'>
				<LoginPage />
			</LoginRoute>
			<LoginRoute path='/unsubscribe/:id'>
				<Suspense fallback={<AuthorizePage />}>
					<UnsubscribePage />
				</Suspense>
			</LoginRoute>

			<Route path='/privacy-policy'>
				<Suspense fallback={<AuthorizePage />}>
					<PrivacyPolicy />
				</Suspense>
			</Route>

			<LoginRoute path='/authorize'>
				<AuthorizePage />
			</LoginRoute>

			<PrivateRoute path='/'>
				<Suspense fallback={<AuthorizePage />}>
					<DashboardApp />
				</Suspense>
			</PrivateRoute>
		</Switch>
	);
};

const Router = () => {
	return (
		<ContextProvider>
			<BrowserRouter>
				<Suspense fallback={<div />}>
					{env.REACT_APP_FULL_STORY && <FullStorySetup />}
				</Suspense>

				<Routes />
			</BrowserRouter>
			<GlobalStyle />
		</ContextProvider>
	);
};

export default Router;
