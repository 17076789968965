import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Container } from "../../utils/sharedStyles";
import Logo from "../../components/Logo";
import SplashScreenCtaButton from "../../components/SplashScreenCtaButton";

import splashBanner from "../../assets/alt/splash-screen-banner.png";
import dashboardDemoImage from "../../assets/dashboard-demo.png";

import hiw3 from "../../assets/alt/ease.png";
import hiw2 from "../../assets/alt/time.png";
import hiw1 from "../../assets/alt/business.png";
import { PublicNavbar } from "../../components/Navbar/Navbar";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "antd";
import { COOKIE_CONSENT } from "../../contexts/UserContext";

const LoginPageContainer = styled.div`
	min-height: 100vh;
	width: 100vw;
	font-size: 1rem;
	.product-hunt-embed {
		margin: 1rem 0;
	}
`;

const SplashScreenSection = styled.div`
	min-height: calc(100vh - 5rem);
	grid-gap: 1rem;
	position: relative;
	display: grid;
	place-items: center;

	.splash-screen-grid {
		display: grid;
		grid-gap: 2rem;
		justify-content: space-between;
		grid-template-columns: 1fr 1fr;

		@media (max-width: 770px) {
			grid-template-columns: 1fr;
			margin-top: -4rem;
		}
	}

	.main-heading {
		margin: 0 auto 2rem;
		font-size: 2.6rem;

		@media (max-width: 770px) {
			grid-template-columns: 1fr;
			font-size: 2.5rem;
		}
	}

	.sub-heading {
		font-size: 1rem;
		margin-bottom: 2rem;
	}

	.illustration-container {
		margin-top: -2rem;
		max-width: 100%;
		position: relative;

		@media (max-width: 770px) {
			display: none;
		}

		.blob-bg {
			width: 450px;
			transform: scale(1.2);
			transform-origin: center;
		}

		.illustration {
			position: absolute;
			top: 10%;
			transform-origin: center;
			transform: scale(1.2);
		}

		@media (max-width: 550px) {
			display: none;
		}
	}
`;

const SecondSection = styled.div`
	text-align: center;
	padding: 3rem 0;
	background-color: #f2f2f2;
	margin-top: 3rem;

	.content-wrapper {
		margin: 0 auto;
		padding: 2rem 1.5rem;
		position: relative;
		@media (max-width: 550px) {
			padding: 2rem 0;
		}

		.dashboard-preview {
			margin-top: calc(-15vh - 3rem);
			margin-bottom: 3rem;
			border-radius: 1rem;
			box-shadow: 0 0 10px -5px #c3c3c3;
		}

		h2 {
			font-size: 3rem;
			@media (max-width: 770px) {
				font-size: 2.3rem;
			}
		}

		p {
			margin: 0 auto;
			max-width: 500px;
		}

		.scribbles-1,
		.scribbles-2 {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 180px;
		}

		.scribbles-2 {
			left: 0;
			transform: translateY(-50%) rotateY(180deg);
		}
	}
`;

const ThirdSection = styled.div`
	padding: 3rem 0;

	.content-grid {
		display: grid;
		grid-gap: 4rem;
		grid-template-columns: 400px 400px;
		justify-content: center;
		margin: 9rem 0;
		align-items: center;

		@media (max-width: 770px) {
			grid-template-columns: 300px;
			margin: 3rem 0;
			grid-gap: 2rem;
		}

		&:nth-child(2n) {
			direction: rtl;
		}

		.details-wrapper {
			text-align: center;

			h3 {
				font-size: 2rem;
			}
		}

		.illustration-wrapper {
			img {
				max-height: 250px;
				transform: scale(1.5);

				@media (max-width: 770px) {
					display: block;
					margin: 0 auto;
					transform: scale(1);

					max-height: 200px;
				}
			}
		}
	}
`;

const FourthSection = styled.div`
	padding: 4rem 0;
	text-align: center;
	h1 {
		max-width: 500px;
		margin: 0 auto 2rem;
	}
`;

const Footer = styled.div`
	padding: 1rem 0;
	background-color: #ffe9c1;
	text-align: center;

	@media (max-width: 550px) {
		padding: 2rem 0;
	}

	.content-grid {
		display: grid;
		grid-gap: 2rem;
		align-items: center;
		justify-content: space-between;
		grid-template-columns: 200px min-content;

		@media (max-width: 550px) {
			grid-template-columns: 1fr;
		}

		.logo-container {
			height: 2rem;
			svg {
				height: 100%;
			}
		}

		.link-wrapper {
			display: grid;
			grid-gap: 4rem;
			grid-auto-columns: max-content;
			grid-auto-flow: column;

			@media (max-width: 550px) {
				grid-auto-flow: row;
				text-align: center;
				grid-gap: 1rem;
			}

			a {
				text-align: left;
			}
		}
	}
`;

const CookieModal = styled.div`
	position: fixed;
	background: white;
	width: 15rem;
	z-index: 1000;
	bottom: 1rem;
	right: 2rem;
	box-shadow: 0px 10px 20px 0px #acacac;
	padding: 1rem;
	border-radius: 6px;

	.action-button {
		width: 100%;
		margin: 1rem 0 0.5rem 0;
	}
	.title {
		display: block;
		font-weight: 600;
		margin-bottom: 1rem;
	}
`;


const StyledBlockingMessage = styled.div`
	padding: .5rem 1rem;
	border-radius: .2rem;
	background-color: #ffeeb9;
	font-size: .9rem;

	strong{
		font-size: 1rem;
		display: block;
	}
`
const BlockingMessage = () => <StyledBlockingMessage>
	<strong>We have closed any new registrations</strong> <br />
  We got a tremendous response on shanc, and we are glad that this is a problem that others relate to as well.
  After talking to our customers, we have created a plan to improve the app and are currently working on executing it properly.

	<br />
	<br />
	We appreciate all the support. Thanks.
	<br />
	- Team shanc
</StyledBlockingMessage>

type TParams = {};

interface Props extends RouteComponentProps<TParams> { }

const LoginPage: React.FC<Props> = props => {
	const [shouldDisplayBanner, setShouldDisplayBanner] = useState<boolean>(
		false
	);

	const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>(false);

	const handleProductHuntBanner = () => {
		if (props.location.search === "?ref=producthunt") {
			setShouldDisplayBanner(true);
		}
	};

	const handleRouteToProductHunt = () => {
		window.open("https://www.producthunt.com/posts/shanc");
	};

	const handleConsentSubmission = async () => {
		localStorage.setItem(COOKIE_CONSENT, JSON.stringify(true));
		setIsCookieAccepted(true);
	};

	useEffect(() => {
		handleProductHuntBanner();
		const localStorageCookieConsent = localStorage.getItem(COOKIE_CONSENT);

		const cookieConsent: boolean = !!localStorageCookieConsent
			? JSON.parse(localStorageCookieConsent)
			: false;

		setIsCookieAccepted(cookieConsent);
	}, []);

	return (
		<>
			{!isCookieAccepted && (
				<CookieModal>
					<div className='title'>Cookiiieeeee!!!!</div> This site uses
					cookies so that you can experience best performance of this
					site
					<Button
						onClick={handleConsentSubmission}
						className='action-button'
						type='primary'
					>
						Got it
					</Button>
				</CookieModal>
			)}
			<LoginPageContainer>
				<PublicNavbar />
				<SplashScreenSection>
					<Container>
						<div className='splash-screen-grid'>
							<div className='splash-screen-content'>
								<h1 className='main-heading'>
									Making Google Analytics easier for makers
								</h1>
								<p className='sub-heading'>
									An app that helps you to get actionable
									takeaways from your Google analytics in
									minutes
								</p>
								<BlockingMessage />
								{/* <SplashScreenCtaButton /> */}
								{shouldDisplayBanner && (
									<>
										<div className='product-hunt-embed'>
											<a
												href='https://www.producthunt.com/posts/shanc?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-shanc'
												target='_blank'
											>
												<img
													src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=205661&theme=light'
													alt='Shanc - Making Google Analytics better for Makers | Product Hunt Embed'
													style={{
														width: "250px",
														height: "54px"
													}}
													width='250px'
													height='54px'
												/>
											</a>
										</div>
									</>
								)}
							</div>
							<div className='illustration-container'>
								<img
									className='illustration'
									src={splashBanner}
									alt=''
								/>
							</div>
						</div>
					</Container>
				</SplashScreenSection>
				<SecondSection>
					<Container className='content-wrapper'>
						<img
							className='dashboard-preview'
							src={dashboardDemoImage}
							alt=''
						/>
						<h2>Simplify your analytics today</h2>
						<p>
							Helps your focus on simplified metrics removing all
							the confusion from your GA reports
						</p>
					</Container>
				</SecondSection>
				<ThirdSection>
					<Container>
						<div className='content-grid'>
							<div className='illustration-wrapper'>
								<img src={hiw2} alt='' />
							</div>
							<div className='details-wrapper'>
								<h3>Get reports delivered to you weekly</h3>
								<p>
									Weekly reports delivered Monday every week
									keeping you up to date with all your
									projects' analytics
								</p>
							</div>
						</div>
						<div className='content-grid'>
							<div className='illustration-wrapper'>
								<img src={hiw1} alt='' />
							</div>
							<div className='details-wrapper'>
								<h3>
									Customize metrics as per your preference
								</h3>
								<p>
									Every project is different and required
									different metrics to evaluate growth. You
									are free to edit which metrics you want to
									prioritize
								</p>
							</div>
						</div>
						<div className='content-grid'>
							<div className='illustration-wrapper'>
								<img src={hiw3} alt='' />
							</div>
							<div className='details-wrapper'>
								<h3>Manage analytics accounts with ease</h3>
								<p>
									We provide a simple and intuitive dashboard
									showing data from your multiple google
									analytics on a single screen.
								</p>
							</div>
						</div>
					</Container>
				</ThirdSection>
				<Footer>
					<Container>
						<div className='content-grid'>
							<Logo type='dark' />
							<div className='link-wrapper'>
								<a
									className='contact-us'
									target='_blank'
									href='mailto:workwithutkarsh@gmail.com'
								>
									Contact Us
								</a>
								<a href='/privacy-policy'>Privacy Policy</a>
							</div>
						</div>
					</Container>
				</Footer>
			</LoginPageContainer>
		</>
	);
};

export default withRouter(LoginPage);
