import { MetricType, QueryMetricName } from "../utils/gapi";

export interface AnalyticsQueryResponse {
	result: GaQueryResult;
	body: string;
	headers: Headers;
	status: number;
	statusText?: any;
}
export interface TotalsForAllResults {
	"ga:visits": string;
}
export interface ColumnHeader {
	name: string;
	columnType: string;
	dataType: string;
}
export interface ProfileInfo {
	profileId: string;
	accountId: string;
	webPropertyId: string;
	internalWebPropertyId: string;
	profileName: string;
	tableId: string;
}
export interface Query {
	"start-date": string;
	"end-date": string;
	ids: string;
	dimensions: string;
	metrics: string[];
	sort: string[];
	filters: string;
	"start-index": number;
	"max-results": number;
}
export interface GaQueryResult {
	kind: string;
	id: string;
	query: Query;
	itemsPerPage: number;
	totalResults: number;
	selfLink: string;
	profileInfo: ProfileInfo;
	containsSampledData: boolean;
	columnHeaders: ColumnHeader[];
	totalsForAllResults: TotalsForAllResults;
}

export type AnalyticsProfileItemListResponse = GapiResponse<GaProfileItem[]>;
export interface GaProfileItem {
	id: string;
	kind: string;
	selfLink: string;
	accountId: string;
	webPropertyId: string;
	internalWebPropertyId: string;
	name: string;
	currency: string;
	timezone: string;
	websiteUrl: string;
	type: string;
	permissions: Permissions;
	created: string;
	updated: string;
	eCommerceTracking: boolean;
	parentLink: LinkMeta;
	childLink: LinkMeta;
}

export type AnalyticsWebPropertyItemListResponse = GapiResponse<
	GaWebPropertyItem[]
>;
export interface GaWebPropertyItem {
	id: string;
	kind: string;
	selfLink: string;
	accountId: string;
	internalWebPropertyId: string;
	name: string;
	websiteUrl: string;
	level: string;
	profileCount: number;
	industryVertical: string;
	defaultProfileId: string;
	dataRetentionTtl: string;
	dataRetentionResetOnNewActivity: boolean;
	permissions: Permissions;
	created: string;
	updated: string;
	parentLink: LinkMeta;
	childLink: LinkMeta;
	profiles?: GaProfileItem[];
}

export type AnalyticsProjectListResponse = GapiResponse<GaAccountItem[]>;
export interface GaAccountItem {
	id: string;
	kind: string;
	selfLink: string;
	name: string;
	permissions: Permissions;
	created: string;
	updated: string;
	childLink: LinkMeta;
	webProperties?: GaWebPropertyItem[];
}

// Gapi Response Generic Type
export interface GapiResponse<T> {
	result: Result<T>;
	body: string;
	headers: Headers;
	status: number;
	statusText?: any;
}

export interface Headers {
	"cache-control": string;
	"content-encoding": string;
	"content-length": string;
	"content-type": string;
	date: string;
	etag: string;
	server: string;
	vary: string;
}

export interface Result<T> {
	kind: string;
	username: string;
	totalResults: number;
	startIndex: number;
	itemsPerPage: number;
	items: T;
}

export interface Permissions {
	effective: string[];
}

export interface LinkMeta {
	type: string;
	href: string;
}

export interface AccountSummary {
	kind: string;
	username: string;
	totalResults: number;
	startIndex: number;
	itemsPerPage: number;
	items: GaAccountItem[];
}
export type AnalyticsSummaryResponse = GapiResponse<GaAccountItem[]>;

export interface QueryResponse {
	reports: Report[];
}

export interface Report {
	columnHeader: QueryResponseColumnHeader;
	data: Data;
}

export interface Data {
	rows: Row[];
	totals: Metric[];
	rowCount: number;
	minimums: Metric[];
	maximums: Metric[];
}

export interface Row {
	dimensions: string[];
	metrics: Metric[];
}

export interface Metric {
	values: string[];
}

export interface QueryResponseColumnHeader {
	dimensions: QueryMetricName[];
	metricHeader: MetricHeader;
}

export interface MetricHeader {
	metricHeaderEntries: MetricHeaderEntry[];
}

export interface MetricHeaderEntry {
	name: QueryMetricName;
	type: MetricType;
}

export interface ChartData {
	value: number;
	name: string;
}

export interface AddWorker {
	accountId: string;
	active: boolean;
	created: Date;
	displayName: string;
	profileId: string;
	webPropertyId: string;
}

export enum SelectedTimePeriod {
	thisWeek = "thisWeek",
	thisMonth = "thisMonth",
	allTime = "allTime",
	custom = "custom",
	today = "today",
}
