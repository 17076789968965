import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoImage } from "../,./../../assets/logo/Color Light Rectangle.svg";
import { ReactComponent as LightImage } from "../,./../../assets/logo/Dark Rectangle.svg";
import { ReactComponent as DarkImage } from "../,./../../assets/logo/Light Rectangle.svg";

import { ReactComponent as SmLogoImage } from "../,./../../assets/logo/Color Light Square.svg";
import { ReactComponent as SmLightImage } from "../,./../../assets/logo/Light Sqaure.svg";
import { ReactComponent as SmDarkImage } from "../,./../../assets/logo/Dark Square.svg";

const LogoContainer = styled.div`
  svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

interface LogoProps {
  type?: "white" | "dark" | "color";
  size?: "default" | "sm";
}
const Logo: React.FC<LogoProps> = ({ type = "color", size = "default" }) => {
  return (
    <LogoContainer className="logo-container">
      {size === "default" && (
        <>
          {type === "color" && <LogoImage />}
          {type === "dark" && <DarkImage />}
          {type === "white" && <LightImage />}
        </>
      )}

      {size === "sm" && (
        <>
          {type === "color" && <SmLogoImage />}
          {type === "dark" && <SmDarkImage />}
          {type === "white" && <SmLightImage />}
        </>
      )}
    </LogoContainer>
  );
};

export default Logo;
