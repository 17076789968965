import {
	AnalyticsProjectListResponse,
	AnalyticsWebPropertyItemListResponse,
	AnalyticsProfileItemListResponse,
	AnalyticsQueryResponse,
	AnalyticsSummaryResponse,
} from "./../contracts/gapi";
import { firebaseConfig, db, firestoreCollections } from "./firebase";
import { parseChangePercentage, shortenNumber } from "./prettyDate";
declare var gapi: any;

const padZero = (num: number): string => (num < 10 ? "0" + num : num + "");

/*
  Copied all the queries functions from
  https://github.com/google/google-api-javascript-client/blob/e40cae42d77c84a437ea28ddf51a59a670ef2ed5/samples/analytics/v3/hello_analytics_api_v3.js#L165
*/

/**
 * Utility method to return the lastNdays from today in the format yyyy-MM-dd.
 * @param {Number} n The number of days in the past from tpday that we should
 *     return a date. Value of 0 returns today.
 */
function lastNDays(n: number) {
	const today = new Date();
	const before = new Date();
	before.setDate(today.getDate() - n);

	const year = before.getFullYear();
	const month = before.getMonth() + 1;
	const day = before.getDate();

	return [year, padZero(month), padZero(day)].join("-");
}

/**
 * Executes a query to the Management API to retrieve all the users accounts.
 * Once complete, handleAccounts is executed. Note: A user must have gone
 * through the Google APIs authorization routine and the Google Anaytics
 * client library must be loaded before this function is called.
 */
export const getAnalyticsAccounts = (): Promise<AnalyticsProjectListResponse> =>
	gapi.client.analytics.management.accounts.list();

export const getAccountSummary = async (): Promise<AnalyticsSummaryResponse> =>
	gapi.client.analytics.management.accountSummaries.list();

/**
 * Executes a query to the Management API to retrieve all the users
 * webproperties for the provided accountId. Once complete,
 * handleWebproperties is executed.
 * @param {String} accountId The ID of the account from which to retrieve
 *     webproperties.
 */
export const getAnalyticsWebPropertiesByAccountId = (
	accountId: string
): Promise<AnalyticsWebPropertyItemListResponse> =>
	gapi.client.analytics.management.webproperties.list({
		accountId,
	});

/**
 * Executes a query to the Management API to retrieve all the users
 * profiles for the provided accountId and webPropertyId. Once complete,
 * handleProfiles is executed.
 * @param {String} accountId The ID of the account from which to retrieve
 *     profiles.
 * @param {String} webpropertyId The ID of the webproperty from which to
 *     retrieve profiles.
 */
export const getAnalyticsProfilesByAccountId = (
	accountId: string,
	webPropertyId: string
): Promise<AnalyticsProfileItemListResponse> =>
	gapi.client.analytics.management.profiles.list({
		accountId,
		webPropertyId,
	});

/**
 * Execute a query to the Core Reporting API to retrieve the top 25
 * organic search terms by visits for the profile specified by profileId.
 * Once complete, handleCoreReportingResults is executed.
 * @param {String} profileId The profileId specifying which profile to query.
 */
// export const queryCoreReportingApi = (
// 	profileId: string
// ): Promise<AnalyticsQueryResponse> =>
// 	gapi.client.analytics.data.ga.get({
// 		ids: "ga:" + profileId,
// 		"start-date": lastNDays(14),
// 		"end-date": lastNDays(0),
// 		metrics: "ga:visits",
// 		dimensions: "ga:source,ga:keyword",
// 		sort: "-ga:visits,ga:source",
// 		filters: "ga:medium==organic",
// 		"max-results": 25
// 	});

export const initializeGapi = async () => {
	if (typeof gapi === "undefined") {
		await new Promise((resolve) => setTimeout(resolve, 2000));
	}
	if (typeof gapi === "undefined") {
		await new Promise((resolve) => setTimeout(resolve, 5000));
	}
	if (typeof gapi === "undefined") {
		await new Promise((resolve) => setTimeout(resolve, 10000));
	}
	try {
		await new Promise((resolve) => gapi.load("client:auth2", resolve));
		const gapiClientParams = {
			apiKey: firebaseConfig.apiKey,
			clientId: firebaseConfig.clientId,
			scope: firebaseConfig.scopes.join(" "),
			discoveryDocs: firebaseConfig.discoveryDocs,
			access_type: "offline",
		};
		console.log("initializeGapi -> gapiClientParams", gapiClientParams);
		await gapi.client.init(gapiClientParams);
		await gapi.client.load("analytics", "v3");
	} catch (error) {
		console.error(error);
	}
};

interface QueryResult {
	reports: QueryReport[];
}

interface QueryReport {
	columnHeader: QueryColumnHeader;
	data: QueryData;
}

interface QueryData {
	rows?: QueryRow[];
	totals: QueryMetric[];
	rowCount: number;
	minimums: QueryMetric[];
	maximums: QueryMetric[];
}

interface QueryRow {
	dimensions: QueryMetricName[];
	metrics: QueryMetric[];
}

interface QueryMetric {
	values: string[];
}

interface QueryColumnHeader {
	dimensions: QueryMetricName[];
	metricHeader: QueryMetricHeader;
}

interface QueryMetricHeader {
	metricHeaderEntries: QueryMetricHeaderEntry[];
}

export type QueryMetricName = "ga:newUsers" | Dimensions | Metrics;

export enum Metrics {
	GAUniquePageViews = "ga:uniquePageviews",
	GAPageViews = "ga:pageviews",
	GASessions = "ga:sessions",
}

export enum Dimensions {
	GABrowser = "ga:browser",
	GACountry = "ga:country",
	GADeviceCategory = "ga:devicecategory",
	GAPagePath = "ga:pagePath",
	GASource = "ga:source",
}

export const DimensionLabels = {
	[Dimensions.GABrowser]: "Browser",
	[Dimensions.GACountry]: "Country",
	[Dimensions.GADeviceCategory]: "Device Category",
	[Dimensions.GAPagePath]: "Pages Visited",
	[Dimensions.GASource]: "Top Referrers",
};

export const MetricLabels = {};

export type QueryDimensionName = "ga:source";

export type MetricType = "INTEGER" | "TIME" | "PERCENT";

interface QueryMetricHeaderEntry {
	name: QueryMetricName;
	type: MetricType;
}

export const parseMetricValue = (
	metricType: MetricType,
	thisMonthValue: string,
	lastMonthValue: string
): { value: number | string; change: number | null } => {
	let value: number | string = 0;
	let change: number | null = null;
	switch (metricType) {
		case "INTEGER": {
			const parsedThisMonthValue = parseInt(thisMonthValue);
			const parsedLastMonthValue = parseInt(lastMonthValue);

			value = shortenNumber(parsedThisMonthValue);
			change = parseChangePercentage(
				parsedThisMonthValue,
				parsedLastMonthValue
			);
			return { value, change };
		}
		case "PERCENT": {
			const parsedThisMonthValue = parseFloat(thisMonthValue);
			const parsedLastMonthValue = parseFloat(lastMonthValue);

			value = parsedThisMonthValue;
			change = parseChangePercentage(
				parsedThisMonthValue,
				parsedLastMonthValue
			);
			return { value, change };
		}
		case "TIME": {
			const parsedThisMonthValue = parseInt(thisMonthValue);
			const parsedLastMonthValue = parseInt(lastMonthValue);

			value = parseInt(thisMonthValue);
			change = parseChangePercentage(
				parsedThisMonthValue,
				parsedLastMonthValue
			);
			return { value, change };
		}

		default: {
			return { value: 0, change: null };
		}
	}
};

export interface ReportTemplate {
	id: string;
	dimension: QueryDimensionName;
	metric: QueryMetricName;
	label: string;
	isDefault: boolean;
}

export const getDefaultReportTemplates = async (): Promise<
	ReportTemplate[]
> => {
	const response = await db
		.collection(firestoreCollections.TEMPLATES)
		.where("isDefault", "==", true)
		.get();

	return response.docs.map((a) => ({
		...a.data(),
		id: a.id,
	})) as ReportTemplate[];
};

export const getReportTemplates = async (): Promise<ReportTemplate[]> => {
	const response = await db.collection(firestoreCollections.TEMPLATES).get();

	return response.docs.map((a) => ({
		...a.data(),
		id: a.id,
	})) as ReportTemplate[];
};
