import { useEffect } from "react";
import ReactGA from "react-ga";
import env from "../../utils/env";
import { useLocation } from "react-router";

if (env.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS);
} else {
  console.log("ga is not initialized");
}

const usePageViews = (): null => {
  const location = useLocation();

  useEffect(() => {
    if (env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return null;
};

export default usePageViews;
