import React, { useState, useContext, useEffect } from "react";
import { initializeGapi } from "../utils/gapi";
import { message } from "antd";

export interface GapiClientState {
	gapiClient: any;
	loading: boolean;
}
const GapiClientContext = React.createContext<GapiClientState | null>(null);

export const GapiClientContextProvider: React.FC = (props) => {
	const [gapiClient, setGapiClient] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const loadGapi = async () => {
		try {
			await initializeGapi();
			setGapiClient(gapi.client);
		} catch (error) {
			console.error(error);
			message.error("Please enable cookies to continue");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadGapi();
	}, []);
	return (
		<GapiClientContext.Provider value={{ gapiClient, loading }}>
			{props.children}
		</GapiClientContext.Provider>
	);
};

export const useGapiClient = (): GapiClientState => {
	const data = useContext<GapiClientState | null>(GapiClientContext);

	if (!data) {
		throw new Error("The GapiClient Provider is not wrapped");
	}

	return data;
};
