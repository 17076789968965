import { SelectedTimePeriod } from "../contracts/gapi";

// Takes an ISO time and returns a string representing how
// long ago the date represents.
export function prettyDate(time: string) {
	var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
		diff = (new Date().getTime() - date.getTime()) / 1000,
		day_diff = Math.floor(diff / 86400);

	if (isNaN(day_diff) || day_diff < 0) return;

	return (
		(day_diff === 0 &&
			((diff < 60 && "just now") ||
				(diff < 120 && "1 minute ago") ||
				(diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
				(diff < 7200 && "1 hour ago") ||
				(diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
		(day_diff === 1 && "Yesterday") ||
		(day_diff < 7 && day_diff + " days ago") ||
		Math.ceil(day_diff / 7) + " weeks ago"
	);
}

export const parsePercentage = (curr: number, total: number) =>
	!!total ? (curr / total) * 100 : null;

export const parseChangePercentage = (curr: number, last: number) =>
	!!last ? ((curr - last) / last) * 100 : null;

export const filterTypeToStartDate = (
	selectedFilter: SelectedTimePeriod
): Date | null => {
	const today = new Date();

	if (selectedFilter === SelectedTimePeriod.thisMonth) {
		return new Date(today.getFullYear(), today.getMonth(), 1);
	}

	if (selectedFilter === SelectedTimePeriod.thisWeek) {
		return new Date(today.setDate(today.getDate() - today.getDay()));
	}

	return null;
};

const formatter = new Intl.DateTimeFormat("en", {
	year: "numeric",
	month: "long",
	day: "numeric",
});

export const filterTypeToDateRange = (
	selectedFilter: SelectedTimePeriod
): string => {
	const today = new Date();

	if (selectedFilter === SelectedTimePeriod.today) {
		return formatter.format(today);
	}

	const startTime = filterTypeToStartDate(selectedFilter);
	if (typeof (formatter as any).formatRange === "function") {
		return (formatter as any).formatRange(startTime, today);
	} else {
		return `${formatter.format(
			startTime || undefined
		)} - ${formatter.format(today || undefined)}`;
	}
};

export const shortenNumber = (value: number): string => {
	if (value >= 1000000000000000) {
		const v = parseFloat(
			(((value / 1000000000000000) * 100) / 100).toFixed(2)
		);
		return `${v} Q`;
	} else if (value >= 1000000000000) {
		const v = parseFloat(
			(((value / 1000000000000) * 100) / 100).toFixed(2)
		);
		return `${v} T`;
	} else if (value >= 1000000000) {
		const v = parseFloat((((value / 1000000000) * 100) / 100).toFixed(2));
		return `${v} B`;
	} else if (value >= 1000000) {
		const v = parseFloat((((value / 1000000) * 100) / 100).toFixed(2));
		return `${v} M`;
	} else if (value >= 1000) {
		const v = parseFloat((((value / 1000) * 100) / 100).toFixed(2));
		return `${v} K`;
	} else {
		return value.toString();
	}
};
