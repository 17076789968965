import * as firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import env from "./env";

if (!env.REACT_APP_API_KEY) {
  throw new Error("Firebase API keys are not provided");
}

export const firebaseConfig = {
  apiKey: env.REACT_APP_API_KEY,
  authDomain: env.REACT_APP_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_DATABASE_URL,
  projectId: env.REACT_APP_PROJECT_ID,
  storageBucket: env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_APP_ID,
  measurementId: env.REACT_APP_MEASUREMENT_ID,
  clientId: env.REACT_APP_GCP_CLIENT_ID,
  scopes: [
    "email",
    "profile",
    "https://www.googleapis.com/auth/analytics.readonly"
  ],
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/analytics/v3/rest"
  ]
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();

const enableFirebasePersistenceFeatures = async () => {
  try {
    db.enablePersistence({ synchronizeTabs: true });
  } catch (error) {
    console.error(error);
  }
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  } catch (error) {
    console.error(error);
  }
};

enableFirebasePersistenceFeatures();

export const firestoreCollections = {
  USERS: "user",
  WORKERS: "worker",
  TEMPLATES: "report-template"
};

export const getUserRef = (userId: string) =>
  db.doc(`${firestoreCollections.USERS}/${userId}`);

export const getUserWorkerCollectionRef = (userId: string) =>
  db.collection(
    `${firestoreCollections.USERS}/${userId}/${firestoreCollections.WORKERS}`
  );

export const getWorkerRef = (userId: string, workerId: string) =>
  db.doc(
    `${firestoreCollections.USERS}/${userId}/${firestoreCollections.WORKERS}/${workerId}`
  );

firebase.analytics();
