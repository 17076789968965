import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";
import {
	firebaseApp,
	db,
	firestoreCollections,
	getUserWorkerCollectionRef,
	getUserRef,
} from "../utils/firebase";
import {
	useDocumentData,
	useCollectionData,
} from "react-firebase-hooks/firestore";
import { GaAccountItem } from "../contracts/gapi";
import { Modal } from "antd";
import env from "../utils/env";
import { ShancWorker } from "../contracts/worker";
declare var gapi: any;

export enum UserType {
	hacker = "hacker",
	startup = "startup",
}

export type PermissionType = "granted" | "denied";

export interface NotificationsSpan {
	daily: boolean;
	weekly: boolean;
	monthly: boolean;
}

export interface UserNotificationPermission {
	email: NotificationsSpan;
	slack: boolean;
}
export interface UserNotification {
	permission: UserNotificationPermission;
	timezone: string;
}
export interface User {
	cookie_consent: boolean;
	id: string;
	family_name: string;
	name: string;
	picture: string;
	locale: string;
	email: string;
	given_name: string;
	uid: string;
	verified_email: boolean;
	notification: UserNotification;
	tokens?: {
		refresh_token: string;
		id_token: string;
		access_token: string;
	};
	data?: GaAccountItem[];
	type: UserType;
}

export interface UserState {
	user: User | null;
	loading: boolean;
	error: any;
}

export interface UserActions {
	login: (code: string) => Promise<void>;
	creatingMultipleWorkers: (data: Partial<ShancWorker>[]) => Promise<void>;
	syncWorkers: (data: GaAccountItem[]) => Promise<void>;
	logout: () => void;
	denyEmailNotification: () => Promise<void>;
	activateEmailNotification: () => Promise<void>;
}

const UserContext = React.createContext<UserState | null>(null);
const UserFunctionsContext = React.createContext<UserActions | null>(null);

export const COOKIE_CONSENT = "COOKIE_CONSENT";

const UserCacheKey = "USER_CACHE";
const userCache = localStorage.getItem(UserCacheKey);
const defaultUserData: User = (userCache && JSON.parse(userCache)) || null;

export const UserContextProvider: React.FC = (props) => {
	const [activeUserId, setActiveUserId] = useState(
		defaultUserData ? defaultUserData.id : null
	);

	const [user, loading, error] = useDocumentData<User>(
		(activeUserId &&
			db.doc(`${firestoreCollections.USERS}/${activeUserId}`)) ||
			null
	);

	const syncWorkers = async (data: GaAccountItem[]) => {
		if (!activeUserId) {
			throw new Error("user is not ready yet");
		}
		const docRef = getUserRef(activeUserId);
		await docRef.set({ data }, { merge: true });
	};

	const login = async (code: string) => {
		try {
			// send auth code to backend
			const timezoneOffset = -new Date().getTimezoneOffset();
			const hoursTimezone = `${
				Math.floor(timezoneOffset / 60) >= 0 ? "" : "-"
			}${Math.floor(timezoneOffset / 60) >= 10 ? "" : "0"}${Math.floor(
				Math.abs(timezoneOffset / 60)
			)}`;
			const minutesTimezone = `${
				Math.abs(timezoneOffset % 60) >= 10 ? "" : "0"
			}${Math.abs(timezoneOffset % 60)}`;
			const timezone = `${hoursTimezone}:${minutesTimezone}`;

			const request = await fetch(
				`${env.REACT_APP_FIREBASE_FUNCTION}/login?code=${code}&timezone=${timezone}`
			);
			const authResponse = await request.json();

			if (authResponse.error) {
				throw new Error(authResponse.message);
			}

			// link tokens to firebase
			const credential = firebase.auth.GoogleAuthProvider.credential(
				authResponse.tokens.id_token,
				authResponse.tokens.access_token
			);

			const firebaseAuthResponse = await firebaseApp
				.auth()
				.signInWithCredential(credential);

			const user = firebaseAuthResponse.user?.providerData[0] || null;

			(window as any).$crisp?.push(["set", "user:email", user?.email]);
			localStorage.setItem(UserCacheKey, JSON.stringify(user));
			setActiveUserId(authResponse.userId);
		} catch (error) {
			console.error(error);

			Modal.error({
				title: "Something went wrong",
				content: error.message,
				onOk: () => {
					window.location.href = "/";
				},
			});
		}
	};

	const logout = async () => {
		try {
			await firebase.auth().signOut();
			const auth2 = gapi.auth2.getAuthInstance();
			await auth2.signOut();
			setActiveUserId(null);
		} catch (error) {
			console.error(error);
		} finally {
			const cookieConsent: string =
				localStorage.getItem(COOKIE_CONSENT) ?? JSON.stringify(false);
			localStorage.clear();
			localStorage.setItem(COOKIE_CONSENT, cookieConsent);
		}
	};

	const creatingMultipleWorkers = async (
		selectedWorkers: Partial<ShancWorker>[]
	) => {
		if (!user) {
			throw new Error("user is not ready");
		}
		const workerCollection = await getUserWorkerCollectionRef(user.id);
		const batch = db.batch();

		selectedWorkers.forEach((r) => {
			const newDocRef = workerCollection.doc();
			const worker = {
				...r,
				created: new Date().toISOString(),
			};
			batch.set(newDocRef, { ...worker, id: newDocRef.id });
		});

		const userRef = getUserRef(user.id);
		batch.set(userRef, { isReady: true }, { merge: true });

		await batch.commit();
	};

	const denyEmailNotification = async () => {
		const key = `notification.permission.email`;
		const userRef = db.doc(`${firestoreCollections.USERS}/${user?.id}`);

		await userRef.update({ [key]: "deny" });
	};
	const activateEmailNotification = async () => {
		window.open(
			"https://us-central1-shanc-dc2c3.cloudfunctions.net/authRedirect",
			"_blank"
		);
	};

	useEffect(() => {
		if (!!user) {
			localStorage.setItem(UserCacheKey, JSON.stringify(user));
		}
	}, [user]);

	useEffect(() => {
		if (!!user) {
			gapi.client.setToken(defaultUserData?.tokens);
		}
	}, []);

	return (
		<UserFunctionsContext.Provider
			value={{
				login,
				logout,
				creatingMultipleWorkers,
				syncWorkers,
				denyEmailNotification,
				activateEmailNotification,
			}}
		>
			<UserContext.Provider
				value={{
					user: (!!activeUserId && (user || defaultUserData)) || null,
					loading,
					error,
				}}
			>
				{props.children}
			</UserContext.Provider>
		</UserFunctionsContext.Provider>
	);
};

export const useUser = (): UserState => {
	const userState = useContext<UserState | null>(UserContext);

	if (!userState) {
		throw new Error("The Provider is not present");
	}

	return userState;
};

export const useUserActions = (): UserActions => {
	const userState = useContext<UserActions | null>(UserFunctionsContext);

	if (!userState) {
		throw new Error("The Provider is not present");
	}

	return userState;
};

const workerListCache = localStorage.getItem("project_list");
export const useWorkers = (): {
	workers: ShancWorker[] | null;
	syncWorkers: (response: GaAccountItem[]) => void;
	loading: boolean;
	error: any;
} => {
	const userState = useContext<UserState | null>(UserContext);
	const userActions = useContext<UserActions | null>(UserFunctionsContext);

	if (!userState) {
		throw new Error("The UserState Provider is not present");
	}

	if (!userActions) {
		throw new Error("The UserActions Provider is not present");
	}

	const activeUserId = userState?.user?.id || null;
	const [workers, loading, error] = useCollectionData<ShancWorker>(
		(activeUserId && getUserWorkerCollectionRef(activeUserId)) || null
	);

	const workersWithData = workers;

	useEffect(() => {
		if (workersWithData) {
			localStorage.setItem(
				"project_list",
				JSON.stringify(workersWithData)
			);
		} else {
			localStorage.removeItem("project_list");
		}
	}, [workersWithData]);

	return {
		workers:
			workersWithData ||
			(workerListCache && JSON.parse(workerListCache)) ||
			null,
		syncWorkers: userActions.syncWorkers,
		loading,
		error,
	};
};

export const useUserState = (): [string | null] => {
	const userState = useContext<UserState | null>(UserContext);

	const userId = userState?.user?.id || null;

	return [userId];
};
