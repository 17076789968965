type envKeys =
  | "REACT_APP_API_KEY"
  | "REACT_APP_AUTH_DOMAIN"
  | "REACT_APP_DATABASE_URL"
  | "REACT_APP_PROJECT_ID"
  | "REACT_APP_STORAGE_BUCKET"
  | "REACT_APP_MESSAGING_SENDER_ID"
  | "REACT_APP_APP_ID"
  | "REACT_APP_MEASUREMENT_ID"
  | "REACT_APP_GCP_CLIENT_ID"
  | "REACT_APP_FIREBASE_FUNCTION"
  | "REACT_APP_GOOGLE_ANALYTICS"
  | "REACT_APP_CRISP"
  | "REACT_APP_FULL_STORY";

// const processEnv = process.env as Record<envKeys, string | undefined>;
const env: Record<envKeys, string | null> = (Object.keys(
  process.env
) as envKeys[]).reduce(
  (total, curr) => ({
    ...total,
    [curr]: !!process.env[curr]?.length ? process.env[curr] : null
  }),
  {} as any
);

export default env;
